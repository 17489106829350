exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js-content-file-path-content-about-index-mdx": () => import("./../../../src/templates/about.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/about/index.mdx" /* webpackChunkName: "component---src-templates-about-js-content-file-path-content-about-index-mdx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-post-js-content-file-path-content-blog-2020-activity-spreadsheet-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/blog/2020_activity_spreadsheet/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-blog-2020-activity-spreadsheet-index-mdx" */),
  "component---src-templates-post-js-content-file-path-content-blog-2020-annual-review-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/blog/2020_annual_review/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-blog-2020-annual-review-index-mdx" */),
  "component---src-templates-post-js-content-file-path-content-blog-2021-annual-review-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/blog/2021_annual_review/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-blog-2021-annual-review-index-mdx" */),
  "component---src-templates-post-js-content-file-path-content-blog-attention-economy-attention-economy-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/blog/attention_economy/attention_economy.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-blog-attention-economy-attention-economy-mdx" */),
  "component---src-templates-post-js-content-file-path-content-blog-augmented-cognition-augmented-cognition-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/blog/augmented_cognition/augmented_cognition.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-blog-augmented-cognition-augmented-cognition-mdx" */),
  "component---src-templates-post-js-content-file-path-content-blog-creativity-factory-creativity-factory-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/blog/creativity_factory/creativity_factory.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-blog-creativity-factory-creativity-factory-mdx" */),
  "component---src-templates-post-js-content-file-path-content-blog-how-to-read-a-book-how-to-read-a-book-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/blog/how_to_read_a_book/how_to_read_a_book.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-blog-how-to-read-a-book-how-to-read-a-book-mdx" */),
  "component---src-templates-post-js-content-file-path-content-blog-lego-thoughts-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/blog/lego_thoughts/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-blog-lego-thoughts-index-mdx" */),
  "component---src-templates-post-js-content-file-path-content-blog-quit-my-job-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/blog/quit_my_job/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-blog-quit-my-job-index-mdx" */),
  "component---src-templates-post-js-content-file-path-content-blog-social-learning-twitter-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/blog/social_learning_twitter/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-blog-social-learning-twitter-index-mdx" */),
  "component---src-templates-post-js-content-file-path-content-blog-zettelkasten-zettelkasten-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/blog/zettelkasten/zettelkasten.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-blog-zettelkasten-zettelkasten-mdx" */),
  "component---src-templates-post-js-content-file-path-content-projects-dots-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/projects/dots/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-projects-dots-index-mdx" */),
  "component---src-templates-post-js-content-file-path-content-projects-lake-como-app-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/projects/lake_como_app/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-projects-lake-como-app-index-mdx" */),
  "component---src-templates-post-js-content-file-path-content-projects-shapes-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/projects/shapes/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-projects-shapes-index-mdx" */),
  "component---src-templates-post-js-content-file-path-content-projects-volta-pagina-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/projects/volta_pagina/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-projects-volta-pagina-index-mdx" */),
  "component---src-templates-post-js-content-file-path-content-projects-waves-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/Users/lorenzo/ittaboba.com/content/projects/waves/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-content-projects-waves-index-mdx" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */)
}

